import React, { useState, Fragment, useEffect } from 'react';
import type { FC } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { SmartCardProvider } from '@atlaskit/link-provider';
import { xcss, Flex, Box } from '@atlaskit/primitives';
import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';

import {
	MacroExperienceFailure,
	MacroExperienceSuccess,
	EDITOR,
	RENDERER,
} from '@confluence/macro-tracker';

import type { LinkCardsParameters } from '../../link-cards/linkCardsTypes';
import { CardSizes } from '../../link-cards/linkCardsTypes';
import {
	useCardsCombinedExperienceState,
	useValidateRequiredParameters,
	ExtraSmallCard,
	HeroCard,
} from '../../link-cards/LinkCardsContent';
import { cardsDimensions } from '../../link-cards/LinkCardsContent/GridCardWrapper';
import { carouselExtensionType } from '../carouselConstants';
import { CardSelectedToEditWrapper } from '../../shared-components/CardSelectedToEditWrapper';

import { CardStrip } from './CardStrip';

export type CarouselContentProps = {
	extensionLocalId: string;
	parameters: LinkCardsParameters;
	isInViewMode?: boolean;
	contentId: string;
	experienceName: string;
	createAnalyticsEvent?: CreateUIAnalyticsEvent;
};

const HeroWrapperHidden = xcss({
	display: 'none',
	width: '100%',
});
const HeroWrapperVisible = xcss({
	display: 'initial',
	width: '100%',
});

const i18n = defineMessages({
	heroCardLabel: {
		id: 'custom-sites-extensions.carousel.hero-card.label',
		defaultMessage: 'slide {positionInSet} of {setSize}',
		description: 'Accessibility label text that is used for each hero card in the carousel',
	},
});

export const CarouselContent: FC<CarouselContentProps> = ({
	extensionLocalId,
	parameters,
	isInViewMode,
	contentId,
	experienceName,
	createAnalyticsEvent,
}) => {
	const intl = useIntl();

	const [selectedHeroCardIndex, setSelectedHeroCardIndex] = useState<number>(0);
	const validatedParameters = useValidateRequiredParameters(parameters);
	const { size, cards, isAvatarShown, isPublishDateShown } = validatedParameters;

	const { areAllCardsLoaded, cardFailureError, onCardSucceeded, onCardFailed } =
		useCardsCombinedExperienceState(cards);

	const cardHeight = cardsDimensions[size].height;

	useEffect(() => {
		if (selectedHeroCardIndex > cards.length - 1) {
			setSelectedHeroCardIndex(cards.length - 1);
		}
	}, [cards.length, selectedHeroCardIndex]);

	const hero = (
		<Fragment>
			{cards.map((card, index) => {
				// Rendering of all cards is required to ensure that the cards are preloaded for the User
				return (
					<Box
						xcss={selectedHeroCardIndex === index ? HeroWrapperVisible : HeroWrapperHidden}
						testId={`hero-card-${index}`}
						key={`hero-card-${index}`}
					>
						<HeroCard
							contentId={contentId}
							cardHeight={cardsDimensions[CardSizes.HERO].height}
							isAvatarShown={isAvatarShown}
							isPublishDateShown={isPublishDateShown}
							isInViewMode={!!isInViewMode}
							onCardSucceeded={onCardSucceeded}
							onCardFailed={onCardFailed}
							analyticsSource="carouselExtensionConfig"
							extensionType={carouselExtensionType}
							createAnalyticsEvent={createAnalyticsEvent}
							isEmptyCard={!card.link}
							ariaLabel={intl.formatMessage(i18n.heroCardLabel, {
								positionInSet: index + 1,
								setSize: cards.length,
							})}
							{...card}
						/>
					</Box>
				);
			})}
		</Fragment>
	);

	return (
		<SmartCardProvider>
			<Flex wrap="wrap" gap="space.150" testId="carousel-content-container">
				{hero}
				<CardStrip
					cardIds={cards.map((card) => card.cardId)}
					selectedHeroCardIndex={selectedHeroCardIndex}
					setSelectedHeroCardIndex={setSelectedHeroCardIndex}
					cards={cards.map((card, index) => (
						<CardSelectedToEditWrapper
							key={card.cardId}
							cardId={card.cardId}
							extensionLocalId={extensionLocalId}
						>
							<ExtraSmallCard
								contentId={contentId}
								cardHeight={cardHeight}
								isAvatarShown={isAvatarShown}
								isPublishDateShown={isPublishDateShown}
								showEmoji
								isInViewMode={!!isInViewMode}
								onCardSucceeded={onCardSucceeded}
								onCardFailed={onCardFailed}
								isEmptyCard={!card.link}
								createAnalyticsEvent={createAnalyticsEvent}
								isCardSelected={selectedHeroCardIndex === index}
								analyticsSource="carouselExtensionConfig"
								extensionType={carouselExtensionType}
								{...card}
							/>
						</CardSelectedToEditWrapper>
					))}
				/>
			</Flex>
			{areAllCardsLoaded && (
				<MacroExperienceSuccess
					name={experienceName}
					mode={isInViewMode ? RENDERER : EDITOR}
					contentId={contentId}
				/>
			)}
			{!!cardFailureError && (
				<MacroExperienceFailure
					name={experienceName}
					contentId={contentId}
					mode={isInViewMode ? RENDERER : EDITOR}
					error={cardFailureError || new Error(`Link cards failed to render`)}
					attributes={{}}
					source="CarouselContent"
				/>
			)}
		</SmartCardProvider>
	);
};
